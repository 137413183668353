.w-100 {
width:100% !important;
}

.pt-45px {
padding-top:45px !important;
}

@media screen and (min-width: 992px) {.pl-lg-65px {
padding-left:65px !important;
}

}
 .m-0 {
margin:0px !important;
}

.font-weight-400 {
font-weight:400 !important;
}

.letter-spacing-0 {
letter-spacing:0px !important;
}

.p-0 {
padding:0px !important;
}

.py-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.ml-20px {
margin-left:20px !important;
}

.m-0 {
margin:0px !important;
}

.py-5px {
padding-top:5px !important;padding-bottom:5px !important;
}

.h-100 {
height:100% !important;
}

.w-100 {
width:100% !important;
}

.m-0 {
margin:0px !important;
}

.pb-100px {
padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-10px {
padding-top:10px !important;padding-bottom:10px !important;
}

}
 .w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.mr-2 {
margin-right:2px !important;
}

.px-3 {
padding-left:3px !important;padding-right:3px !important;
}

.h-100 {
height:100% !important;
}

.pb-15px {
padding-bottom:15px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.pb-25px {
padding-bottom:25px !important;
}

@media screen and (min-width: 992px) {.py-lg-45px {
padding-top:45px !important;padding-bottom:45px !important;
}

}
 @media screen and (min-width: 1200px) {.py-xl-125px {
padding-top:125px !important;padding-bottom:125px !important;
}

}
 .mb-5px {
margin-bottom:5px !important;
}

.mb-60px {
margin-bottom:60px !important;
}

.line-height-34px {
line-height:34px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.p-0 {
padding:0px !important;
}

.opacity-90 {
opacity:90 !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.p-0 {
padding:0px !important;
}

.py-100px {
padding-top:100px !important;padding-bottom:100px !important;
}

@media screen and (min-width: 992px) {.py-lg-110px {
padding-top:110px !important;padding-bottom:110px !important;
}

}
 .mb-65px {
margin-bottom:65px !important;
}

.mb-30px {
margin-bottom:30px !important;
}

.w-100 {
width:100% !important;
}

.mb-20px {
margin-bottom:20px !important;
}

.py-200px {
padding-top:200px !important;padding-bottom:200px !important;
}

.opacity-80 {
opacity:80 !important;
}

.mb-10px {
margin-bottom:10px !important;
}

.mb-60px {
margin-bottom:60px !important;
}

.py-50px {
padding-top:50px !important;padding-bottom:50px !important;
}

.pb-40px {
padding-bottom:40px !important;
}

.m-0 {
margin:0px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.pb-40px {
padding-bottom:40px !important;
}

@media screen and (min-width: 992px) {.pb-lg-10px {
padding-bottom:10px !important;
}

}
 .mb-0 {
margin-bottom:0px !important;
}

.mx-20px {
margin-left:20px !important;margin-right:20px !important;
}

.mb-15px {
margin-bottom:15px !important;
}

@media screen and (min-width: 1200px) {.ml-xl-5px {
margin-left:5px !important;
}

}
 .font-size-16px {
font-size:16px !important;
}

.mx-10px {
margin-left:10px !important;margin-right:10px !important;
}

.p-0 {
padding:0px !important;
}

.p-0 {
padding:0px !important;
}

.m-0 {
margin:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.w-100 {
width:100% !important;
}

.p-0 {
padding:0px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.pt-4 {
padding-top:4px !important;
}

.mb-0 {
margin-bottom:0px !important;
}

.mx-1 {
margin-left:1px !important;margin-right:1px !important;
}

.pt-2 {
padding-top:2px !important;
}

@media screen and (min-width: 768px) {.pt-md-4 {
padding-top:4px !important;
}

}
 .pb-4 {
padding-bottom:4px !important;
}

.px-2 {
padding-left:2px !important;padding-right:2px !important;
}

.px-4 {
padding-left:4px !important;padding-right:4px !important;
}

.py-4 {
padding-top:4px !important;padding-bottom:4px !important;
}

.w-100 {
width:100% !important;
}

.pt-15px {
padding-top:15px !important;
}

.mr-5px {
margin-right:5px !important;
}

.ml-4 {
margin-left:4px !important;
}

.mb-25px {
margin-bottom:25px !important;
}

.p-0 {
padding:0px !important;
}

