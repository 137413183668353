
header.header-section {
  z-index: $zindex-fixed;

  .ph {
    font-size: 14px;
    letter-spacing: 0;
  }

  .mod {
      font-size: 16px;
      letter-spacing: .15em;
  }

  .p-name {
    .h1, .h2, .h3, .h4, .h5, .h6 {
      letter-spacing: 0;
    }
  }
  
  &.scrolling .container-fluid.py-30px.py-lg-40px.bg-white {
      padding: 15px 15px !important;
  }
  
  .container-fluid.py-30px.py-lg-40px.bg-white {
      transition: .3s ease;   
  }
  
  @include media-breakpoint-down(lg) {
      .container {
          max-width: 100%;
      }
  }
  
  .phone-col {
      // svg:not([class*="angle-down"]),
      // i:not([class*="angle-down"]) {
      //     display: none !important;
      // }
      ul[id*="rblPhones"] a{
          font-size: 12px !important;
          text-transform: uppercase;
          color: $primary !important;
          letter-spacing: 0.15em;
      }
      .phone-dropdown-menu ul, .address-dropdown-menu ul {
          padding: 0;
          margin: 0;
      }
      .phone-dropdown-menu ul a .row, 
      .address-dropdown-menu ul a .row {
          padding: .5rem .25rem .5rem .5rem;
          position: relative;
      }
      .phone-dropdown-menu ul a, 
      .address-dropdown-menu ul a {
          line-height: 1.4em;
          transition: 0.3s ease;
          opacity: 0;
          visibility: hidden;
          transform: translate3d(0, 1rem, 0);
          display: block;
      }
  }

  nav {
      .icons {
          .dropdown-menu {
              min-width: 15rem;
              border: none;
              box-shadow: 0px 2px 7px rgba(0,0,0,0.2);
              padding: 10px;
              div[id*="container"] a { 
                  color: $primary!important;
                  font-size: 12px!important;
                  text-transform: uppercase;
                  letter-spacing: .15em;
                  font-weight: bold;
                  line-height: 1.5;
                  display: block;
                  padding: .5rem .25rem .5rem .5rem;
                  position: relative;
                  &:before {
                      content: '';
                      display: block;
                      position: absolute;
                      z-index: -1;
                      top: 5%;
                      bottom: 5%;
                      left: 5%;
                      right: 5%;
                      transition: .3s;
                      background-color: rgba(15,20,26,.1)!important;
                      opacity: 0;
                  }
                  &:hover {
                      &:before {
                          right: 0;
                          left: 0;
                          top: 0;
                          bottom: 0;
                          opacity: 1;
                      }
                  }
              }
              div[id*="container"] span { 
                  font-weight: 400;
                  color: #242220!important;
              }
          }
      }
      
      @include media-breakpoint-up(lg) {
          ul#desktopMenu {
              ul {
                  li {
                      a {
                          position: relative;
                          &::before {
                              content: '';
                              display: block;
                              position: absolute;
                              z-index: -1;
                              top: 5%;
                              bottom: 5%;
                              left: 5%;
                              right: 5%;
                              transition: .3s;
                              background-color: rgba($gray-200,0.15)!important;
                              opacity: 0;
                          }
                          &:hover {
                              &::before {
                                  opacity: 1;
                                  top: 0;
                                  bottom: 0;
                                  left: 0;
                                  right: 0;
                              }
                          }
                          &.current {
                              &::before {
                                  opacity: 1;
                                  top: 0;
                                  bottom: 0;
                                  left: 0;
                                  right: 0;
                              }
                          }
                      }
                  }
              }
          }
      }
  }

  .search-toggle {
    margin: 5px 0 0 0;
    color: rgba($color: #ffffff, $alpha: 0.75);
  
    &:hover {
      color: rgba($color: #ffffff, $alpha: 1);
    }
  }
  .brdr {
    &::before {
      content: "";
      position: relative;
      border-left: 1px solid rgba($color: #ffffff, $alpha: 0.75);
      margin: 0 25px 0 15px;
      top: -1px;
    }
  }
}

ul#desktopMenu.justify-content-center {
justify-content: end !important;
}

@media screen and (min-width: 992px) {
  ul#desktopMenu {
      > li {
          > a {
              position: relative;
              &.current,
              &.highlighted,
              &:hover {
                  &:before {
                      background: #ffffff !important;
                      opacity: 1 !important;
                      left: 0;
                      right: 0;
                  }
              }
              &:before {
                  content: '';
                  position: absolute;
                  transition: .3s ease;
                  bottom: 0;
                  left: 50%;
                  right: 50%;
                  height: 2px;
                  border-radius: 0;
                  background: transparent;
                  opacity: 0;
              }
          }
      }
  }
}