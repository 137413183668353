section.contact-form {
    .bg-blue {
        background-color: #457b9d !important;
    }
    .trees {
        min-height: 673px;
        padding: 50px 0 0;
    }
    .dog1, .dog2 {
        min-height: 673px;
    }

    ::placeholder,
    .form-control {
        font-size: 14px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.15em;
    }

    .form-group {
        margin-top: 0px  !important;
        margin-bottom: 10px !important;
    }

    .qc-form-wrapper {
        padding: 0 165px;
    }

    textarea {
        min-height: 142px;
    }
    
    [id*="btnSend"] {
        max-width: 196px;
    }
    
    @media screen and (max-width: 1800px) {
        .qc-form-wrapper {
            padding: 70px 0;
        }
    }
    @media screen and (max-width: 1600px) {
        .qc-form-wrapper {
            padding: 50px 0;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            padding-top: 100px;
        }
    }
    @media screen and (max-width: 991px) {
        .qc-form-wrapper {
            margin-bottom: 0;
            padding: 50px 0;
        }
    }
    @media screen and (max-width: 767px) {
        .qc-form-wrapper {
            padding: 50px 15px;
        }
    }
    &.no-services {
        @media screen and (min-width: 992px) {    
            & {
                padding-top: 300px !important;
            }
        }
        
        @media screen and (max-width: 991px) {   
            & {
                padding-top: 0px !important;
            } 
        }
        &.no-testimonials {
            padding-top: 30px !important;
        }
    }

}
